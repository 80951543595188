import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Container, CardForm, TitleForm } from "../../styles/styledComponents";  
import { sendSmtpDomainSetup } from "./services/shop.service";

export function ShopCreate() {
  const [nombre, setNombre] = useState(localStorage.getItem("nombre") || ""); // Cargar desde localStorage
  const [dominio, setDominio] = useState(localStorage.getItem("dominio") || "");
  const [successfulSend, setSuccessfulSend] = useState(false);
  const [errorSend, setErrorSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Guardar la información en localStorage
    localStorage.setItem("nombre", nombre);
    localStorage.setItem("dominio", dominio);
    
    navigate("/crear-tiendaet2"); // Redirigir a la segunda etapa
  };

  const sendMainDomainSetupSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try{
      const respose = await sendSmtpDomainSetup(dominio);
      if(respose.errorCode){
        console.error("Error sending mail", respose);
        setErrorSend(true);
        return;
      }
      else{
        setSuccessfulSend(true);
      }
      setIsLoading(false);
    }catch (error){
      console.error("Error", error);
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <CardForm>
          <TitleForm>CREAR TIENDA</TitleForm>
          <Form onSubmit={handleSubmit}>
            <Label>Nombre de la Tienda</Label>
            <Input 
              type="text" 
              id="nombre" 
              value={nombre} 
              onChange={(e) => setNombre(e.target.value)} 
              pattern="[A-Za-z0-9\s]+" 
              title="Solo se permiten letras, números y espacios"
            />
            <Button type="submit">Continuar</Button>
          </Form>
          <br/><br/>
          <div style={{ color: 'black', backgroundColor: '#ADD8E6', padding: '20px', borderRadius: '10px' }}>
            <p >Si necesitas asociar un dominio a tu tienda, es requerido contactarse con nuestro equipo de soporte para realizar dicha configuración.</p>
            <br/>
            <Form onSubmit={sendMainDomainSetupSubmit} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ position: 'relative', width: '100%' }}>
                <Input value={dominio} placeholder="Ingresa tu dominio" onChange={(e) => setDominio(e.target.value)} style={{ paddingRight: '100px' }} />
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <Button type="submit" style={{ position: 'relative', left: '10px', background: '#5a9bb0' }}>
                    Enviar Correo
                  </Button>
                )}
                {successfulSend && (
                  <div style={{ background: '#739c60', color:'#ffffff', margin: '10px', padding:'10px', borderRadius:'5px' }}>
                  ✉️¡Correo enviado correctamente! Te contactaremos a la brevedad.
                  </div>)}
                {errorSend && (
                  <div style={{ color: '#9bce7e' , marginTop: '10px' }}>
                  Error al enviar el correo. Por favor, inténtalo de nuevo.
                  </div>)}
              </div>
            </Form>
            </div>
      </CardForm>
    </Container>
  );
}

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  
`;

// const Title = styled.h1`
//   margin-bottom: 20px;
// `;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #333;
  }
`;
