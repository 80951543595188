import { Routes, Route } from "react-router-dom";
import ProtectedLayout from "../components/ProtectedLayout";
import PublicLayout from "../components/PublicLayout";

// Rutas Empresa
import { Empresa } from "../pages/empresa/CompanyDashboard";
import { CompanyCreate } from "../pages/empresa/CompanyCreate";
import { CompanyEdit } from "../pages/empresa/CompanyEdit";

// Rutas Login
import { HomePage } from "../pages/login/HomePage";
import { TermsAndConditionsPage } from "../pages/login/TermsAndConditionsPage";
import { WhowearePage } from "../pages/login/WhowearePage";

import Error from "../pages/login/Error";


// Rutas Opciones
import { Planes } from "../pages/planes/planes";
import { Tiendas } from "../pages/tienda/tiendas";

// Rutas Pedidos
import { OrderDashboard } from "../pages/orders/OrdersDashboard";
import { OrderDetail } from "../pages/orders/OrdersDetail";
import { OrderEdit } from "../pages/orders/OrdersEdit";

// Rutas Productos
import { ProductsRead } from "../pages/products/ProductsRead";
import { ProductsCreate } from "../pages/products/ProductsCreate";
import { ProductsEdit } from "../pages/products/ProductsEdit";
import { ProductsDetail } from "../pages/products/ProductsDetail";

// Rutas Categorias
import { CategoriesRead } from "../pages/categorias/CategoriesRead";
import { CategoriesCreate } from "../pages/categorias/CategoriesCreate";
import { CategoriesEdit } from "../pages/categorias/CategoriesEdit";

// Rutas Usuarios
import { UserDashboardAdmin } from "../pages/usuarios/UserDashboardAdmin";
import { UserDashboard } from "../pages/usuarios/UserDashboard";
import { UserCreate } from "../pages/usuarios/UserCreate";
import { UserActive } from "../pages/usuarios/UserActive";
import { UserEdit } from "../pages/usuarios/UserEdit";
import { UsuariosDetail } from "../pages/usuarios/UserRead";

// Rutas Tienda
import { ShopCreate } from "../pages/tienda/ShopCreate";
import { CrearTiendaEtapa2 } from "../pages/tienda/ShopCreateStep2";
import { CrearTiendaEtapa3 } from "../pages/tienda/ShopCreateStep3";
import { CrearTiendaEtapa4 } from "../pages/tienda/ShopCreateStep4";
import { CrearTiendaEtapa5 } from "../pages/tienda/ShopCreateStep5";
import { CrearTiendaEtapa6 } from "../pages/tienda/ShopCreateStep6";
import { CrearTiendaEtapa7 } from "../pages/tienda/ShopCreateStep7";
import { CrearTiendaEtapa8 } from "../pages/tienda/ShopCreateStep8";
import { CrearTiendaEtapa9 } from "../pages/tienda/ShopCreateStep9";
import { ShopEdit } from "../pages/tienda/ShopEdit";
import { ShopInfoEdit } from "../pages/tienda/ShopInfoEdit";

export function MyRoutes() {
  return (
    <Routes>
      
      <Route
        path="/"
        element={
          <PublicLayout>
            <HomePage />
          </PublicLayout>
        }
      />
      <Route path="/terminos-y-condiciones" element={<TermsAndConditionsPage />} />
      <Route path="/quienes-somos" element={<WhowearePage />} />

      <Route element={<ProtectedLayout />}>
        {/* Rutas Protegidas */}
        <Route path="/tienda" element={<Tiendas />} />
        <Route path="/tienda-categorias" element={<CategoriesRead />} />
        <Route path="/tienda-categorias-crear" element={<CategoriesCreate />} />
        <Route path="/tienda-categorias-editar" element={<CategoriesEdit />} />
        <Route path="/tienda-productos" element={<ProductsRead />} />
        <Route path="/tienda-productos-crear" element={<ProductsCreate />} />
        <Route path="/tienda-productos-ver" element={<ProductsDetail />} />
        <Route path="/tienda-productos-editar" element={<ProductsEdit />} />
        <Route path="/tienda-pedidos" element={<OrderDashboard />} />
        <Route path="/tienda-verpedido" element={<OrderDetail />} />
        <Route path="/tienda-editpedidos" element={<OrderEdit />} />
        <Route path="/crear-tienda" element={<ShopCreate />} />
        <Route path="/crear-tiendaet2" element={<CrearTiendaEtapa2 />} />
        <Route path="/crear-tiendaet3" element={<CrearTiendaEtapa3 />} />
        <Route path="/crear-tiendaet4" element={<CrearTiendaEtapa4 />} />
        <Route path="/crear-tiendaet5" element={<CrearTiendaEtapa5 />} />
        <Route path="/crear-tiendaet6" element={<CrearTiendaEtapa6 />} />
        <Route path="/crear-tiendaet7" element={<CrearTiendaEtapa7 />} />
        <Route path="/crear-tiendaet8" element={<CrearTiendaEtapa8 />} />
        <Route path="/crear-tiendaet9" element={<CrearTiendaEtapa9 />} />
        <Route path="/editar-tienda" element={<ShopEdit />} />
        <Route path="/editar-info-tienda" element={<ShopInfoEdit />} />
        <Route path="/empresa" element={<Empresa />} />
        <Route path="/crear-empresa" element={<CompanyCreate />} />
        <Route path="/editar-empresa" element={<CompanyEdit />} />
        <Route path="/planes" element={<Planes />} />
        <Route path="/usuarios" element={<UserDashboard />} />
        <Route path="/usuariosadm" element={<UserDashboardAdmin />} />
        <Route path="/activar-usuario" element={<UserActive />} />
        <Route path="/editar-usuario" element={<UserEdit />} />
        <Route path="/crear-usuario" element={<UserCreate />} />
        <Route path="/ver-usuario" element={<UsuariosDetail />} />
      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
}
