import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom"; // Importar useNavigate
import { Container, Card, TitleContainer, Title, Title2, TableWrapper, Table, Button, TableHeader, TableRow, TableHeaderCell, TableCell, ActionButtonGroup, ActionButton, Breadcrumb } from "../../styles/styledComponents";  
import { getAllusers } from './services/user.services'; // Importar el servicio para obtener usuarios
import {DataTable} from "../../styles/DataTable";

export function UserDashboardAdmin() {
  const navigate = useNavigate(); // Inicializar el hook para navegar
  const [users, setUsers] = useState([]); // Estado para almacenar los usuarios
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de error
  
  useEffect(() => {
    console.log("Mounting component...");
    const fetchUsers = async () => {
      setLoading(true); // Asegurarse de que el estado de carga comience como verdadero
      try {
        const response = await getAllusers();
        console.log("Usuarios obtenidos:", response?.users);
        if (response?.users && Array.isArray(response.users)) {
          console.log("Actualizando usuarios...");
          setUsers(response.users);
        } else {
          console.log("Datos inesperados:", response);
          setError("Formato de datos inválido");
        }
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
        setError("Error al obtener los usuarios");
      } finally {
        setLoading(false); // Asegurarse de actualizar el estado de carga siempre
      }
    };
  
    fetchUsers();
  }, []);

  const columns = [
    { label: "Nombre", accessor: "firstName", width: '30%' },
    { label: "Apellido", accessor: "lastName", width: '20%' },
    { label: "Correo", accessor: "email", width: '30%' },
    { label: "Estado", accessor: "status", render: (value) => ( <StatusLabel status={value === "ACTIVE" ? "ACTIVE" : value === "REJECTED" ? "REJECTED" : "PENDING"}> {value === "ACTIVE" ? "Activo" : value === "REJECTED" ? "Rechazado" : "Pendiente"} </StatusLabel> ), width: '10%'},
    { label: "Razón", accessor: "statusReason", width: '30%' }
  ];

  const actions = [
    {
      icon: <span>👁️</span>,
      onClick: (user) => navigate("/activar-usuario", { state: { userId: user.id } }),
    },
    {
      icon: <span>✏️</span>,
      onClick: (user) => navigate("/editar-usuario", { state: { userId: user.id } }),
    }
  ];

  // Renderizado condicional basado en el estado de carga y error
  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Card>
      <Title2>Todos los Usuarios</Title2>
      <DataTable columns={columns} data={users} actions={actions} rowsPerPage={5} />
    </Card>
    </>
  );
}

// Estilos
const BackButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const StatusLabel = styled.span`
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  background-color: ${(props) => {
    switch (props.status) {
      case "ACTIVE":
        return "#4caf50"; // Verde para Activo
      case "REJECTED":
        return "#f44336"; // Rojo para Rechazado
      case "PENDING":
      default:
        return "#767676"; // Naranja para Pendiente
    }
  }};
`;

