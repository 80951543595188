import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllCategory, deleteCategory } from "./services/Categories.services";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  TableWrapper,
  Table,
  Button,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableCell,
  Breadcrumb
} from "../../styles/styledComponents";
import { ErrorAlert } from "../../components/ErrorAlert";

export const CategoriesRead = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId } = location.state || {}; // Extraemos storeId del estado de navegación

  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Activamos el loading al inicio de la petición
      try {
        const response = await getAllCategory(storeId);
        if (!response?.categories) {
          setError("Error al obtener las categorias");
        } else {
            setCategorias(response.categories);
          if (response.categories.length === 0) {
            setError(null); // Resetear el error si no hay productos pero no hay problema con la respuesta
          }
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError("Error al obtener los categorias");
      } finally {
        setLoading(false); // Aseguramos que el loading se apague siempre
      }
    };

    fetchProducts();
  }, [storeId]);

  const handleDelete = async (categoryId) => {
    try {
      await deleteCategory(categoryId, storeId);
      window.location.reload();
    } catch (error) {
      console.error("Error al eliminar la categoria:", error);
      setError(error); // Almacena el error en el estado para que el componente ErrorAlert lo procese
    }
  };

  if (loading) return <div>Cargando...</div>;

  return (
    <Container>
        {error && <ErrorAlert error={error} />}
      <TitleContainer>
        <Title>TIENDAS</Title>
        <Breadcrumb items={[
            { name: "Inicio", link: "/tienda" },
            { name: "Tiendas", link: "/tienda" },
            { name: "Categorias", link: "/tienda-categorias" }
          ]} />
      </TitleContainer>    
      <Button
            style={buttonStyle2}
            onClick={() =>
              navigate("/tienda-productos", { state: { storeId } })
            }
          >Productos
          </Button>  
      <Card>
        <Header>
          <Title2>CATEGORÍAS</Title2>
          <Button
            style={buttonStyle}
            onClick={() =>
              navigate("/tienda-categorias-crear", { state: { storeId } })
            }
          >Crear Categoría
          </Button>
        </Header>
        <TableWrapper>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>#</TableHeaderCell>
                <TableHeaderCell>Nombre</TableHeaderCell>
                <TableHeaderCell>Descripción</TableHeaderCell>
                <TableHeaderCell>Acción</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {categorias.length === 0 ? (
                <TableRow>
                  <TableCell colSpan="6" style={{ textAlign: "center" }}>
                    {error ? error : "No hay categorias disponibles."}
                  </TableCell>
                </TableRow>
              ) : (
                categorias.map((categoria, index) => (
                  <TableRow key={categoria.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{categoria.name}</TableCell>
                    <TableCell>{categoria.description}</TableCell>
                    <TableCell>
                      <ActionButtonGroup>
                        <ActionButton
                          onClick={() =>
                            navigate("/tienda-categorias-editar", {
                              state: { storeId, categoryId: categoria.id },
                            })
                          }
                        >
                          ✏️
                        </ActionButton>
                        <ActionButton
                          onClick={() => handleDelete(categoria.id)}
                        >
                          🗑️
                        </ActionButton>
                      </ActionButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Card>
    </Container>
  );
};

// Styled components

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableBody = styled.tbody``;

const ProductImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
`;

const ActionButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const ActionButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

const NoProductsMessage = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #888;
`;


const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#d34343', // Color azul
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '20px',
};

const buttonStyle2 = {
  padding: '10px 20px',
  backgroundColor: '#6f6f6f', // Color azul
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  marginBottom: '20px',
};
