import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { createCategories } from "./services/Categories.services"; // Import the createProduct service
import { useLocation } from "react-router-dom";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  Row,
  Column50,
  Form,
  FormRow,
  Label,
  Input,
  TextArea,
  Button,
  Breadcrumb
} from "../../styles/styledComponents";
import { ErrorAlert } from "../../components/ErrorAlert";

export const CategoriesCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId } = location.state || {}; // Extraer storeId del estado de navegación
  const [error, setError] = useState(null); // Estado para almacenar el error si ocurre

  const [formData, setFormData] = useState({
    Name: "", // Nombre del producto
    Description: "" // Descripción
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validación de datos (puedes agregar más validaciones según sea necesario)
      if (!formData.Name || !formData.Description) {
        setError("Por favor, completa todos los campos obligatorios.");
        return;
      }
      const response = await createCategories(formData, storeId);

      if (response.errorCode) {
        console.error("Error creating category:", response);
        return;
      }

      // Redirigir después de crear el producto
      navigate("/tienda-categorias", { state: { storeId: storeId } });
    } catch (error) {
      setError(error); // Captura cualquier error
      console.error("Error creating category:", error);
    }
  };

  return (
    <Container>
      {error && <ErrorAlert error={error} />}

      <TitleContainer>
        <Title>Categorías</Title>
        <Breadcrumb
          items={[{ name: "Inicio", link: "/tienda" }, { name: "Tiendas", link: "/tienda" }, { name: "Crear categoría", link: "/" }]}
        />
      </TitleContainer>

      <Card>
        <Title2>Crear Categoría</Title2>

        <Form onSubmit={handleSubmit} style={{ marginRight: "-20px" }}>
          {}
          <h3>Información de la Categoría</h3>
          <Row>
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  name="Name" // Nombre del campo actualizado
                  value={formData.Name}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Descripción</Label>
                <TextArea
                  style={{ height: "82px" }}
                  name="Description" // Nombre del campo actualizado
                  value={formData.Description}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
            </Column50>
          </Row>

          <Button style={buttonStyle} type="submit">Guardar Categoría</Button>
        </Form>
      </Card>
    </Container>
  );
};

const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#d34343', // Color azul
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    marginRight: '20px'
  };

const ContainerImage = {
  border: '2px dashed #007bff',
  borderRadius: '10px',
  padding: '20px',
  textAlign: 'center',
  backgroundColor: '#f9f9f9',
  cursor: 'pointer',
  alignItems: 'center',
  marginBottom: '20px',
};

const PreviewContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
`;

const PreviewImage = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 0, 0, 0.7);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 12px;
`;

const InfoText = styled.p`
  font-size: 14px;
  color: #555;
`;
