import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Container, CardForm, TitleForm } from "../../styles/styledComponents";

const REGIONES = [
  { key: "rayp", label: "Región de Arica y Parinacota" },
  { key: "rtar", label: "Región de Tarapacá" },
  { key: "rant", label: "Región de Antofagasta" },
  { key: "rata", label: "Región de Atacama" },
  { key: "rcoq", label: "Región de Coquimbo" },
  { key: "rval", label: "Región de Valparaíso" },
  { key: "rmet", label: "Región Metropolitana" },
  { key: "rohi", label: "Región de O'Higgins" },
  { key: "rmau", label: "Región del Maule" },
  { key: "rnub", label: "Región de Ñuble" },
  { key: "rbio", label: "Región del Biobío" },
  { key: "rara", label: "Región de La Araucanía" },
  { key: "rrio", label: "Región de Los Ríos" },
  { key: "rlag", label: "Región de Los Lagos" },
  { key: "rais", label: "Región de Aisén" },
  { key: "rmag", label: "Región de Magallanes" },
];

export const CrearTiendaEtapa8 = () => {
  const navigate = useNavigate();
  const [selectedPayments, setSelectedPayments] = useState({
    pickup: true,
    shipping: false,
    fixedshipping: false
  });
  const [formData, setFormData] = useState({
    regionalShipping: initializeRegionalShipping(),
    regionalFixedShipping: initializeRegionalShipping(), // Asegúrate de que esto se inicialice
    contacto: "",
    emailContacto: "",
  });

  function initializeRegionalShipping() {
    return REGIONES.reduce((acc, region) => {
      acc[region.key] = 0;
      return acc;
    }, {});
  }

  const handleInputChange = ({ target }) => {
    const { name, value, dataset } = target;
    const newValue = value === "" ? 0 : Number(value);
  
    setFormData((prevState) => {
      // Si el input viene del formulario de envío fijo
      if (selectedPayments.fixedshipping) {
        return {
          ...prevState,
          regionalFixedShipping: {
            ...prevState.regionalFixedShipping,
            [name]: newValue
          }
        };
      }
      // Si el input viene del formulario de envío normal
      else if (selectedPayments.shipping) {
        return {
          ...prevState,
          regionalShipping: {
            ...prevState.regionalShipping,
            [name]: newValue
          }
        };
      }
      // Para otros campos
      return { ...prevState, [name]: value };
    });
  };

  const handleCheckboxChange = ({ target: { name, checked } }) => {
    setSelectedPayments((prev) => {
      const updates = {
        [name]: checked,
      };
  
      if (name === "shipping" && checked) {
        updates.fixedshipping = false;
        // Limpiar valores de fixed shipping
        setFormData(prev => ({
          ...prev,
          regionalFixedShipping: initializeRegionalShipping()
        }));
      } else if (name === "fixedshipping" && checked) {
        updates.shipping = false;
        // Limpiar valores de shipping
        setFormData(prev => ({
          ...prev,
          regionalShipping: initializeRegionalShipping()
        }));
      }
  
      return { ...prev, ...updates };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const adaptedData = prepareDataForSubmission();
    console.log("Adapted JSON:", JSON.stringify(adaptedData, null, 2));
    localStorage.setItem("shippingDetails", JSON.stringify(adaptedData.shippingDetails));
    localStorage.setItem("pickUpDetails", JSON.stringify(adaptedData.pickUpDetails));
    navigate("/crear-tiendaet9");
  };

  function prepareDataForSubmission() {
    return {
      storeName: localStorage.getItem("nombre"),
      domain: localStorage.getItem("dominio"),
      shippingDetails: {
        enable: selectedPayments.shipping,
        regionalShipping: formData.regionalShipping,
        regionalFixedShipping: formData.regionalFixedShipping
      },
      pickUpDetails: {
        enable: selectedPayments.pickup,
      },
      contact: {
        phone: formData.contacto,
        email: formData.emailContacto,
        socialNetwork: {
          facebook: localStorage.getItem("redsocialfb"),
          instagram: localStorage.getItem("redsocialig"),
          twitter: localStorage.getItem("redsocialtw"),
        },
        address: localStorage.getItem("direccion"),
        logo: localStorage.getItem("logo"),
        sliderImages: [
          localStorage.getItem("sliderImage1"),
          localStorage.getItem("sliderImage2"),
          localStorage.getItem("sliderImage3"),
        ],
      },
      description: {
        whoWeAre: localStorage.getItem("descrempresa"),
        mission: localStorage.getItem("descrquiensomos"),
        vision: localStorage.getItem("desctermycond"),
      },
    };
  }

  return (
    <Container>
      <CardForm>
        <TitleForm>Ingrese métodos de envío</TitleForm>
        <form onSubmit={handleSubmit}>
          <PaymentOptions
            selectedPayments={selectedPayments}
            onCheckboxChange={handleCheckboxChange}
          />
          {selectedPayments.shipping && (
            <ShippingForm
              regionalShipping={formData.regionalShipping}
              onInputChange={handleInputChange}
            />
          )}
          {selectedPayments.fixedshipping && (
            <FixedShippingForm
            regionalFixedShipping={formData.regionalFixedShipping}
              onInputChange={handleInputChange}
            />
          )}
          <SubmitButton type="submit">Continuar</SubmitButton>
        </form>
      </CardForm>
    </Container>
  );
};

const PaymentOptions = ({ selectedPayments, onCheckboxChange }) => (
  <FormGroup>
    <CheckboxLabel>
      <Checkbox
        type="checkbox"
        name="pickup"
        checked={selectedPayments.pickup}
        onChange={onCheckboxChange}
      />
      Retiro en tienda
    </CheckboxLabel>
    <CheckboxLabel>
      <Checkbox
        type="checkbox"
        name="shipping"
        checked={selectedPayments.shipping}
        onChange={onCheckboxChange}
      />
      Entrega a domicilio (precio porcentaje)
    </CheckboxLabel>
    <CheckboxLabel>
      <Checkbox
        type="checkbox"
        name="fixedshipping"
        checked={selectedPayments.fixedshipping}
        onChange={onCheckboxChange}
      />
      Entrega a domicilio (precio fijo)
    </CheckboxLabel>
  </FormGroup>
);

const ShippingForm = ({ regionalShipping, onInputChange }) => (
  <>
    <h3>Porcentaje de Envío por Región</h3>
    {REGIONES.map(({ key, label }) => (
      <FormGroup key={key}>
        <Label>{label}</Label>
        <Input
          type="number"
          name={key}
          value={regionalShipping[key] || 0}
          onChange={onInputChange}
          min="0"
          max="99"
          style={{ width: "5%", marginRight: "5px" }}
        />
        %
      </FormGroup>
    ))}
  </>
);

const FixedShippingForm = ({ regionalFixedShipping, onInputChange }) => (
  <>
    <h3>Precio de Envío por Región</h3>
    {REGIONES.map(({ key, label }) => (
      <FormGroup key={key}>
        <Label>{label}</Label>
        $ 
        <Input
          type="number"
          name={key}
          value={regionalFixedShipping[key]}
          onChange={(e) => {
            let value = e.target.value === '' ? 0 : Math.max(0, parseInt(e.target.value, 10));
            onInputChange({
              target: {
                name: key,
                value: value,
                dataset: { type: 'fixedshipping' }
              }
            });
          }}
          onBlur={(e) => {
            // Asegurarse de que el valor sea al menos 0 cuando el input pierde el foco
            if (e.target.value === '' || parseInt(e.target.value, 10) < 0) {
              onInputChange({
                target: {
                  name: key,
                  value: 0,
                  dataset: { type: 'fixedshipping' }
                }
              });
            }
          }}
          style={{ width: "7%", marginLeft:"5px" }}
          min="0"
        />
      </FormGroup>
    ))}
  </>
);


// Styled components
const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  margin-right: 20px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #555;
  }
`;

export default CrearTiendaEtapa8;
