import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  Button,
  Form,
  FormRow,
  Label,
  Input,
  Breadcrumb
} from "../../styles/styledComponents";
import { getStore, updateStore } from "./services/shop.service";
import { sendSmtpDomainSetup } from "./services/shop.service";

export function ShopInfoEdit() {
  const [currentComponent, setCurrentComponent] = useState(null); // Componente inicial
  const [storeData, setStoreData] = useState({
    storeName: "",
    dominio: ""
  }); // Datos de la tienda
  const [dominio, setDominio] = useState(localStorage.getItem("dominio") || "");
  const [successfulSend, setSuccessfulSend] = useState(false);
  const [errorSend, setErrorSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { storeId } = location.state || {}; // Obtener storeId desde la navegación

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await getStore(storeId); // Llamar a la API para obtener los datos de la tienda
        setStoreData({
          storeName: response.data.store.storeName,
          domain: response.data.store.domain
        }); // Guardar los datos en el estado
      } catch (error) {
        console.error("Error al obtener los datos de la tienda:", error);
      }
    };
    if (storeId) fetchStoreData(); // Solo hacer la solicitud si hay storeId
  }, [storeId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreData({ ...storeData, [name]: value });
  };

  const sendMainDomainSetupSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      try{
        const respose = await sendSmtpDomainSetup(dominio);
        if(respose.errorCode){
          console.error("Error sending mail", respose);
          setErrorSend(true);
          return;
        }
        else{
          setSuccessfulSend(true);
        }
        setIsLoading(false);
      }catch (error){
        console.error("Error", error);
        setIsLoading(false);
      }
    }

  const handleUpdateStore = async () => {
    try {
      const updateInformationStore = {
        storeName: storeData.storeName,
        domain: storeData.domain
      };

      const response = await updateStore(storeId, updateInformationStore);

      if (response.storeId === storeId) {
        alert("¡Datos actualizados correctamente!");
      }
    } catch (error) {
      alert("Hubo un error al actualizar los datos");
      console.error(error);
    }
  };

  return (
    <Container>
      <TitleContainer>
        <Title>Mis Tiendas</Title>
        <Breadcrumb items={[
          { name: "Inicio", link: "#" },
          { name: "Tiendas", link: "/tienda" },
          { name: "Actualizar información Tienda", link: "/editar-info-tienda" }
        ]} />
      </TitleContainer> 
      <Card style={{width: '60%'}}>
        <Title2>Actualizar Tienda Web</Title2>
        <Layout>
          <MainSection>
            <MainContent>
              <Form>
              <FormRow>
                <Label>
                  Nuevo nombre de la Tienda:</Label>
                  <Input
                    type="text"
                    name="storeName"
                    value={storeData.storeName}
                    onChange={handleInputChange}
                  />
              </FormRow>
              </Form>
            </MainContent>
            <Button
              style={buttonStyle}
              onClick={handleUpdateStore}
            >
              Guardar Cambios
            </Button>
          </MainSection>
        </Layout>
      </Card>
      <div style={{ color: 'black', backgroundColor: '#ADD8E6', padding: '20px', borderRadius: '10px', fontSize: '14px' }}>
            <p >Si necesitas asociar un dominio a tu tienda, es requerido contactarse con nuestro equipo de soporte para realizar dicha configuración.</p>
            <br/>
            <Form onSubmit={sendMainDomainSetupSubmit} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ position: 'relative', width: '100%' }}>
                <Input value={dominio} placeholder="Ingresa tu dominio" onChange={(e) => setDominio(e.target.value)} style={{ paddingRight: '100px' }} />
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <Button type="submit" style={{ position: 'relative', left: '10px', background: '#5a9bb0' }}>
                    Enviar Correo
                  </Button>
                )}
                {successfulSend && (
                  <div style={{ background: '#739c60', color:'#ffffff', margin: '10px', padding:'10px', borderRadius:'5px' }}>
                  ✉️¡Correo enviado correctamente! Te contactaremos a la brevedad.
                  </div>)}
                {errorSend && (
                  <div style={{ color: '#9bce7e' , marginTop: '10px' }}>
                  Error al enviar el correo. Por favor, inténtalo de nuevo.
                  </div>)}
              </div>
            </Form>
            </div>
    </Container>
  );
}

// Estilos

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#d34343', // Color azul
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '20px',
};

const Layout = styled.div`
  display: flex;
  height: 100%; /* Asegura que use todo el alto disponible */
`;

const MainSection = styled.div`
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column; /* Apila MainContent y Button verticalmente */
  justify-content: space-between; /* Asegura que el botón quede al final */
`;

const MainContent = styled.div`
  flex-grow: 1; /* Hace que el contenido principal ocupe el espacio disponible */
`;
