import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Container,
  CardForm,
  TitleForm,
  InfoForm,
} from "../../styles/styledComponents";

export function CrearTiendaEtapa4() {
  const [selectedTemplate, setSelectedTemplate] = useState(
    localStorage.getItem("templateCode") || ""
  );
  const [selectedColorGroup, setSelectedColorGroup] = useState(null);
  const [accordionOpen, setAccordionOpen] = useState(true);

  // New state to store selected colors
  const [colors, setColors] = useState({
    primary: "",
    secondary: "",
    tertiary: "",
  });

  const colorOptions = [
    { label: "Primera opción", colors: ["#BFC8AD", "#90B494", "#718F94"] },
    { label: "Segunda opción", colors: ["#3BA99C", "#69D1C5", "#7EBCE6"] },
    { label: "Tercera opción", colors: ["#F0A6CA", "#EFC3E6", "#F0E6EF"] },
  ];

  // On component mount, load colors, subsections, and logo from localStorage if available
  useEffect(() => {
    const storedColors = localStorage.getItem("storeColors");
    if (storedColors) {
      setColors(JSON.parse(storedColors));
    }
  }, []);

  const navigate = useNavigate();

  // Función para manejar la selección de una plantilla
  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template);
  };
  const handleAccordionToggle = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleColorGroupSelect = (index) => {
    setSelectedColorGroup(index);
    const selectedColors = colorOptions[index].colors;

    const newColors = {
      primary: selectedColors[0],
      secondary: selectedColors[1],
      tertiary: selectedColors[2],
    };

    setColors(newColors);
    localStorage.setItem("storeColors", JSON.stringify(newColors));
  };

  // Función para continuar con la siguiente etapa
  const handleContinue = () => {
    if (selectedColorGroup === null) {
      alert("Por favor seleccione un grupo de colores.");
      return;
    }
    if (selectedTemplate) {
      localStorage.setItem("templateCode", selectedTemplate);
      console.log("templateCode", selectedTemplate);
      navigate("/crear-tiendaet7"); // Cambia esto a la ruta que necesites
    } else {
      alert("Por favor, seleccione una plantilla.");
    }
  };

  return (
    <Container>
      <CardForm>
        <TitleForm>Configura tu Plantilla</TitleForm>
        <TemplatesContainer>
          <Template
            selected={selectedTemplate === "T1"}
            onClick={() => handleSelectTemplate("T1")}
          >
            <TemplateTitle>Plantilla de Tienda</TemplateTitle>
            <a
              href="/templates/T1.html" // Cambia la ruta a la vista previa de la plantilla
              target="_blank" // Abre el enlace en una nueva pestaña
              rel="noopener noreferrer" // Añade una relación de seguridad (?)
            >
              <PreviewButton>Vista previa</PreviewButton>
            </a>
          </Template>
          <Template style={{display:"none"}}
            selected={selectedTemplate === "T2"}
            onClick={() => handleSelectTemplate("T2")}
          >
            <TemplateTitle>Plantilla 2</TemplateTitle>
            <a href="#">
              <PreviewButton>Vista previa</PreviewButton>
            </a>
          </Template>
        </TemplatesContainer>
        <Section>
          <Label>Seleccione Grupo de Colores</Label>
          <Accordion>
            <AccordionHeader onClick={handleAccordionToggle}>
              {accordionOpen ? "Ocultar Colores" : "Mostrar Colores"}
            </AccordionHeader>
            {accordionOpen && (
              <AccordionContent>
                {colorOptions.map((option, index) => (
                  <div key={index}>
                    <OptionLabel>{option.label}</OptionLabel>
                    <ColorGroup>
                      {option.colors.map((color, colorIndex) => (
                        <ColorOption
                          key={colorIndex}
                          style={{
                            backgroundColor: color,
                            border:
                              selectedColorGroup === index
                                ? "3px solid #000"
                                : "none",
                          }}
                          onClick={() => handleColorGroupSelect(index)}
                        />
                      ))}
                    </ColorGroup>
                  </div>
                ))}
              </AccordionContent>
            )}
          </Accordion>
        </Section>
        <ContinueButton onClick={handleContinue}>Continuar</ContinueButton>
      </CardForm>
    </Container>
  );
}

// Estilos con styled-components
{
  /* const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`; */
}

const Title = styled.h2`
  margin-bottom: 20px;
`;

const TemplatesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 20px;
`;

const Template = styled.div`
  font-family: "Roboto", sans-serif;
  width: 200px;
  height: 150px;
  border-radius: 8px;
  background-color: ${({ selected }) => (selected ? "#d3d3d3" : "#f0f0f0")};
  /* border: ${({ selected }) =>
    selected ? "2px solid #9e9e9e" : "2px solid #ccc"}; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
`;

const TemplateTitle = styled.h3`
  margin: 0;
  margin-bottom: 10px;
  font-weight: 500;
`;

const PreviewButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

const ContinueButton = styled.button`
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #333;
  }
`;


// Estilos con styled-components
const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
const Label = styled.label`
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
`;

const Accordion = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const AccordionHeader = styled.div`
  background-color: #ccc;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #bbb;
  }
`;

const AccordionContent = styled.div`
  padding: 10px;
  background-color: #f9f9f9;
`;

const OptionLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;
const ColorGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const ColorOption = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: border 0.15s ease;

  &:hover {
    border: 3px solid #000;
  }
`;
export default CrearTiendaEtapa4;
