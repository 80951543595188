import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { updateOrder } from "./services/orders.services"; // Import the createProduct service
import { ErrorAlert } from "../../components/ErrorAlert";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  Button,
  Form,
  FormRow,
  Select,
  Label,
  Input,
  Breadcrumb 
} from "../../styles/styledComponents";

export const OrderEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const order = location.state || {};
  const { storeId, orderId } = location.state || {}; // Extract storeId and productId from navigation state
  const [error, setError] = useState(null); // Estado para almacenar el error si ocurre

  const [formData, setFormData] = useState({
    status: order.status || "",
    buyerDetails: {
      identificationType: order.buyerDetails?.identificationType || "",
      identificationNumber: order.buyerDetails?.identificationNumber || "",
      email: order.buyerDetails?.email || "",
      phone: order.buyerDetails?.phone || "",
      address: order.buyerDetails?.address || "",
      firstName: order.buyerDetails?.firstName || "",
      lastName: order.buyerDetails?.lastName || "",
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("buyerDetails.")) {
      const key = name.split(".")[1];
      setFormData({
        ...formData,
        buyerDetails: {
          ...formData.buyerDetails,
          [key]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      console.log("datosform",formData)
      const response = await updateOrder(formData, order.id, storeId); // Create or update a product
      console.log("datos actu",response)

      if (response.errorCode) {
        console.error("Error editing product:", response);
        return;
      }
      navigate("/tienda-pedidos", { state: { storeId: storeId } });
    } catch (error) {
      setError(error); // Almacena el error en el estado para que el componente ErrorAlert lo procese
    }
  };

  return (
    <Container>
      {error && <ErrorAlert error={error} />}
      <TitleContainer>
        <Title>Pedidos</Title>
        <Breadcrumb 
        items={[
          { name: "Inicio", link: "/tienda" },
          { name: "Tiendas", link: "/tienda" },
          { name: "Actualizar Pedido", link: "/" },
        ]}
      />
      </TitleContainer> 
      <Card>
        <Title2>Actualizar Pedido #{order.id}</Title2>
        <Form onSubmit={handleSubmit}>
          <FormRow>
            <Label>Estado</Label>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="">No actualizar</option>
              <option value="PENDING_PAYMENT">Pendiente a pago</option>
              <option value="PAID">Pagado</option>
              <option value="IN_PREPARATION">En preparación</option>
              <option value="DELIVERED">Entregado</option>
              <option value="IN_DISTRIBUTION">En Reparto</option>
              <option value="CANCELLED">Cancelado</option>
              {/* Agregar más opciones según el sistema */}
            </Select>
          </FormRow>

          <FormRow>
            <Label>Nombre</Label>
            <Input
              type="text"
              name="buyerDetails.firstName"
              value={formData.buyerDetails.firstName}
              onChange={handleChange}
            />
          </FormRow>

          <FormRow>
            <Label>Apellido</Label>
            <Input
              type="text"
              name="buyerDetails.lastName"
              value={formData.buyerDetails.lastName}
              onChange={handleChange}
            />
          </FormRow>

          <FormRow>
            <Label>RUT</Label>
            <Input
              type="text"
              name="buyerDetails.identificationNumber"
              value={formData.buyerDetails.identificationNumber}
              onChange={handleChange}
            />
          </FormRow>

          <FormRow>
            <Label>Correo</Label>
            <Input
              type="email"
              name="buyerDetails.email"
              value={formData.buyerDetails.email}
              onChange={handleChange}
            />
          </FormRow>

          <FormRow>
            <Label>Teléfono</Label>
            <Input
              type="tel"
              name="buyerDetails.phone"
              value={formData.buyerDetails.phone}
              onChange={handleChange}
            />
          </FormRow>

          <FormRow>
            <Label>Dirección</Label>
            <Input
              type="text"
              name="buyerDetails.address"
              value={formData.buyerDetails.address}
              onChange={handleChange}
            />
          </FormRow>

          <Button type="submit">Actualizar Pedido</Button>
        </Form>
      </Card>
    </Container>
  );
};


export default OrderEdit;
