import axios from "axios";

const API_URL = process.env.REACT_APP_ECOMMERCE_API_URL;

// Servicios para Stor<e

export const createWebStore = (webStoreData) => {
  return axios.post(`${API_URL}/Store/CreateStore`, webStoreData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).data;
};

export const createStore = (storeData) => {
  return axios.post(`${API_URL}/Store`, storeData, {
    headers: {
      // 'Content-Type': 'multipart/form-data',
      // "Content-Type": "application/json", // Specifies that the content type is JSON
      companyId: localStorage.getItem("companyId"), // Retrieves the companyId from local storage
      // companyId: "f5ae1426-6bd2-4ba3-b745-b6b727380149",
    },
  });
};

export const updateStore = async (storeId, storeData) => {
  try {
    const companyId = localStorage.getItem("companyId");
    const response = await axios.put(`${API_URL}/Store/${storeId}`, storeData, {
      headers: {
        companyId,
      },
    });
    console.log("info: update",response)
    alert("¡Tienda actualizada correctamente!");
    return response.data;
  } catch (error) {
    console.error(`Error updating store with ID ${storeId}:`, error);
  }
};

export const updateWebStore = async (storeId, storeData) => {
  try {
    console.log("updateWebStore: ",storeData)
    // const companyId = localStorage.getItem("companyId");
    const response = await axios.put(`${API_URL}/Store/UpdateWebStore`, storeData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    alert("¡Tienda no sido actualizada debido a un error");
    console.error(`Error updating store with ID ${storeId}:`, error);
  }
};


export const getStore = (storeId) => {
  if (!storeId)
    throw new Error("No se encontró el ID de la empresa en localStorage");

  return axios.get(`${API_URL}/Store/${storeId}`);
};

export const deleteStore = async (storeId) => {
  const companyId = localStorage.getItem("companyId");
  const deleteReason = {
    reason: "",
  };

  try {
    const response = await axios.delete(`${API_URL}/Store/${storeId}`, {
      headers: {
        companyId, // Incluye el companyId en los headers
      },
      data: deleteReason, // Incluye el cuerpo de la solicitud aquí
    });
    return response.data;
  } catch (error) {
    alert("¡Tienda no sido eliminada debido a un error");
    console.error(`Error deleting store with ID ${storeId}:`, error);
    throw error;
  }
};


// Servicios para Company
export const getAllStores = async (filterData) => {
  const companyId = localStorage.getItem("companyId");
  console.log("companyId", companyId);
  try {
    // Espera a que la promesa se resuelva
    const response = await axios.post(`${API_URL}/Store/getAll`, filterData, {
      headers: {
        companyId,
            'Content-Type': 'application/json', // Asegura que el content type sea correcto
      },
      
        filter: {
          createDateTime: {
            startDateTime: filterData.startDateTime, // Pass the start date from filterData
            endDateTime: filterData.endDateTime, // Pass the end date from filterData
          }
        }
      });

    return response.data; // Devuelve solo los datos necesarios
  } catch (error) {
    console.error("Error en la solicitud:", error);
    throw error; // Re-lanza el error para que pueda ser manejado en el componente
  }
};

export const updateStoreStatus = async (storeId, status) => {
  try {
    const companyId = localStorage.getItem("companyId");
    console.log("infostatrus",status)
    console.log("infostatrus",status.status)

    const response = await axios.put(
      `${API_URL}/Store/${storeId}/status`,status,

      {
        headers: {
          'Content-Type': 'application/json', // Asegura que el content type sea correcto
          companyId, // Incluye el companyId en los headers
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error; // Lanza el error para que sea manejado por quien llame a esta función
  }
};

export const sendSmtpDomainSetup = async (requestDomain) => {
  try{
    const companyId = localStorage.getItem("companyId");
    const bodyData = {
      code: "DOMAINSETUP",
      domain: requestDomain
    };

    const response = await axios.post(
      `${API_URL}/Smtp/Send`, bodyData,
      {
        headers:{
          'Content-Type': 'application/json',
          companyId
        }
      }
    );

    return response.data;
  }catch (error) {
    throw error;
  }
};

export const updateStoreWebVersion = async (storeId) => {
  if (!storeId)
    throw new Error("No se encontró el ID de la tienda en localStorage");

  return axios.put(`${API_URL}/Store/${storeId}/UpdateStoreWebVersion`);
};
