import axios from "axios";

const API_URL = process.env.REACT_APP_ECOMMERCE_API_URL; 

export const createCategories = async (categoryData, storeId) => {
  try {
    console.log("storeid:", storeId);
    console.log("Categoria datos:", categoryData);

    const response = await axios.post(`${API_URL}/Category`, categoryData, 
      {
        headers: {
          storeId
        }
    });

    console.log("Category created:", response);
    return response.data;
  } catch (error) {
    console.error("Error creating category:", error);
    throw error;
  }
};

// Update an existing product (PUT /Product/{categoryId})
export const updateCategory = async (categoryData, categoryId, storeId) => {
  try {
    const response = await axios.put(`${API_URL}/Category/${categoryId}`, categoryData, {
      headers: {
        storeId
      },
    });
    console.log("datos despues de update",response)
    console.log("response.data",response.data);

    return response.data;
  } catch (error) {
    console.error(`Error updating category with ID ${categoryId}:`, error);
    throw error;
  }
};

// Get a single product by ID (GET /Product/{categoryId})
export const getCategory = async (categoryId, storeId) => {
  try {
    const response = await axios.get(`${API_URL}/Category/${categoryId}`, {
      headers: {
        storeId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching category with ID ${categoryId}:`, error);
    throw error;
  }
};

export const deleteCategory = async (categoryId, storeId) => {
  try {
    const response = await axios.delete(`${API_URL}/Category/${categoryId}`, {
      headers: {
        'Content-Type': 'application/json',
        storeId,
      },
      data: {
        reason: "Categoria eliminado por el usuario" // Ajusta el motivo según lo que necesites
      }
    });

    return response.data;
  } catch (error) {
    console.error(`Error deleting product with ID ${categoryId}:`, error);
    throw error;
  }
};

// Get all products (POST /GetAll)
export const getAllCategory = async (storeId) => {
  try {
    const response = await axios.get(`${API_URL}/Category`,
      {
        headers: {
          storeId,
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching all products:', error);
    throw error;
  }
};