import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom"; 
import { Container, Card, TitleContainer, Title, Title2, TableWrapper,
  Table,
  Button,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableCell,
  ActionButtonGroup, ActionButton, Breadcrumb } from "../../styles/styledComponents";
import { getUsers } from './services/user.services'; 
import { UserDashboardAdmin } from './UserDashboardAdmin'; // Importar el dashboard de admin
import { ErrorAlert } from "../../components/ErrorAlert";

export function UserDashboard() {
  const navigate = useNavigate(); 
  const [users, setUsers] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const [currentUser, setCurrentUser] = useState(null); // Estado para almacenar la información del usuario actual
  const [isAdmin, setIsAdmin] = useState(false); // Estado para verificar si es admin
  
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const response = await getUsers(userId); 
        console.log("Response data:", response.data);

        if (response.data?.isAdmin) {
          setIsAdmin(true); // Si es administrador, actualizar el estado
        }

        setCurrentUser(response.data); // Guardar la información del usuario actual

        if (Array.isArray(response.data)) { 
          setUsers(response.data); 
        } else if (response.data && typeof response.data === 'object') { 
          setUsers([response.data]); 
        } else {
          console.error("Unexpected data format:", response.data);
          setError("Formato de datos inválido");
        }
      } catch (err) {
        console.error("Error fetching users:", err);
        setError(error); // Almacena el error en el estado para que el componente ErrorAlert lo procese
      } finally {
        setLoading(false); 
      }
    };

    fetchUsers(); 
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }


  return (
    <Container>
      {error && <ErrorAlert error={error} />}

      <TitleContainer>
        <Title>USUARIOS</Title>
        <Breadcrumb items={[
          { name: "Inicio", link: "/tienda" },
          { name: "Usuarios", link: "/usuarios" }
        ]} />
      </TitleContainer>  

      {isAdmin && currentUser && (
        <Card>
          <Title2>Información del Administrador</Title2>
          <TableWrapper>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Nombre</TableHeaderCell>
                <TableHeaderCell>Apellido</TableHeaderCell>
                <TableHeaderCell>Correo</TableHeaderCell>
                <TableHeaderCell>Estado</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableRow>
            </TableHeader>
            <tbody>
              <TableRow>
                <TableCell>{currentUser.firstName}</TableCell>
                <TableCell>{currentUser.lastName}</TableCell>
                <TableCell>{currentUser.email}</TableCell>
                <TableCell>
                  <StatusLabel status={currentUser.isEnabled}>
                    {currentUser.isEnabled ? "Activo" : "Inactivo"}
                  </StatusLabel>
                </TableCell>
                <TableCell>
                      <ActionButtonGroup>
                        <ActionButton onClick={() => navigate("/ver-usuario", { state: { userId: currentUser.id } })}>👁️</ActionButton> 
                        <ActionButton onClick={() => navigate("/editar-usuario", { state: { userId: currentUser.id } })}>✏️</ActionButton> 
                      </ActionButtonGroup>
                    </TableCell>
              </TableRow>
            </tbody>
          </Table>
          </TableWrapper>

        </Card>
      )}

      {/* Renderizar el dashboard del administrador */}
      {isAdmin && (
        <>
          <UserDashboardAdmin /> 
        </>
      )}

      {!isAdmin && (
        <Card>
          <TableWrapper>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>#</TableHeaderCell>
                <TableHeaderCell>Nombres</TableHeaderCell>
                <TableHeaderCell>Apellidos</TableHeaderCell>
                <TableHeaderCell>Correo</TableHeaderCell>
                <TableHeaderCell>Estado</TableHeaderCell>
                <TableHeaderCell>Acción</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <tbody>
              {Array.isArray(users) ? (
                users.map((user, index) => (
                  <TableRow key={user.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <StatusLabel status={user.isEnabled}>
                        {user.isEnabled ? "Activo" : "Inactivo"}
                      </StatusLabel>
                    </TableCell>
                    <TableCell>
                      <ActionButtonGroup>
                        <ActionButton onClick={() => navigate("/ver-usuario", { state: { userId: user.id } })}>👁️</ActionButton> 
                        <ActionButton onClick={() => navigate("/editar-usuario", { state: { userId: user.id } })}>✏️</ActionButton> 
                        <ActionButton>🗑️</ActionButton> 
                      </ActionButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="6">No users available</TableCell>
                </TableRow>
              )}
            </tbody>
          </Table>
          </TableWrapper>
        </Card>
      )}
    </Container>
  );
}

// Estilos
const BackButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const StatusLabel = styled.span`
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  background-color: ${(props) =>
    props.status ? "#4caf50" : "#ff6961"};
`;
