import React, { useRef} from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  TableWrapper,
  Table,
  Button,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableCell,
  Breadcrumb
} from "../../styles/styledComponents";
import ShippingInfoImage from "./component/ShippingInfoImage"; // Importa el nuevo componente

export const OrderDetail = () => {
  const location = useLocation();
  const order = location.state || {};
 
  return (
    <Container>
      <TitleContainer>
        <Title>TIENDAS</Title>
        <Breadcrumb items={[
            { name: "Inicio", link: "/tienda" },
            { name: "Tiendas", link: "/tienda" },
            { name: "Detalle", link: "/tienda-verpedido" }
          ]} />
      </TitleContainer>
      <Card>
        <Header>
          <Title2>INFORMACIÓN PEDIDO #{order.id}</Title2>
        </Header>
        <OrderInfo>
          <InfoRow>
            <InfoLabel>Estado:</InfoLabel>
            <StatusBadge color={getEstadoColor(order.status)}>
                      {getEstadoTexto(order.status)}
                    </StatusBadge>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Cliente:</InfoLabel>
            <InfoValue>{order.buyerDetails.firstName} {order.buyerDetails.lastName}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>RUT:</InfoLabel>
            <InfoValue>{order.buyerDetails.identificationNumber}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Fecha:</InfoLabel>
            <InfoValue>{new Date(order.creationDateTime).toLocaleDateString('es-ES', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Telefono:</InfoLabel>
            <InfoValue>{order.buyerDetails.phone}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Metodo de pago:</InfoLabel>
            <InfoValue> {order.paymentDetails.paymentMethod === "bank-transfer" ? "Transferencia" : "Webpay"}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Correo:</InfoLabel>
            <InfoValue>{order.buyerDetails.email}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Método de envío:</InfoLabel>
            <InfoValue>
              {order.shippingMethod === "PICKUP" ? "Retiro en tienda" : "Envío a domicilio"}
            </InfoValue>
          </InfoRow>
          
          {/* Mostrar la dirección solo si el método de envío no es "pickup" */}
          {order.shippingMethod !== "PICKUP" && (
            <>
              <InfoRow>
                <InfoLabel>Dirección:</InfoLabel>
                <InfoValue>{order.buyerDetails.address}</InfoValue>
              </InfoRow>
              {/* <InfoRow>
                <InfoLabel>Comuna:</InfoLabel>
                <InfoValue>{order.buyerDetails.comuna}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Ciudad:</InfoLabel>
                <InfoValue>{order.buyerDetails.city}</InfoValue>
              </InfoRow> */}
            </>
          )}

          {/* Condicional para mostrar ShippingInfoImage solo si shippingMethod es "SHIPPING" */}
          {order.shippingMethod === "SHIPPING" && (
            <ShippingInfoImage
              firstName={order.buyerDetails.firstName}
              lastName={order.buyerDetails.lastName}
              rut={order.buyerDetails.identificationNumber}
              address={order.buyerDetails.address}
              phone={order.buyerDetails.phone}
              order={order.id}
            />
          )}

          

        </OrderInfo>
        <TableContainer>
          <OrderTable>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>#</TableHeaderCell>
                <TableHeaderCell>Nombre</TableHeaderCell>
                <TableHeaderCell>Precio</TableHeaderCell>
                <TableHeaderCell>Tipo de Precio</TableHeaderCell>
                <TableHeaderCell>Cantidad</TableHeaderCell>
                <TableHeaderCell>Precio Total</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {order.productDetails.map((producto, index) => (
                <TableRow key={producto.productId}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{producto.name}</TableCell>
                  <TableCell>${(producto.price).toLocaleString('es-ES')}</TableCell>
                  <TableCell>{producto.priceType}</TableCell>
                  <TableCell>{producto.quantity}</TableCell>
                  <TableCell>
                    $
                    {(producto.total).toLocaleString('es-ES')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </OrderTable>
        </TableContainer>
        <TotalRow>
          <TotalLabel>Precio Envio</TotalLabel>
          <TotalValue>
            $
            {(order.shipping.amount).toLocaleString('es-ES')}
          </TotalValue>
        </TotalRow>
        <TotalRow>
          <TotalLabel>TOTAL</TotalLabel>
          <TotalValue>
            $
            {(order.totalAmount).toLocaleString('es-ES')}
          </TotalValue>
        </TotalRow>
      </Card>
    </Container>
  );
};

const getEstadoColor = (status) => {
  switch (status) {
    case "CANCELLED":
      return "red";
    case "PENDING_PAYMENT":
      return "grey";
    case "IN_PREPARATION":
      return "orange";
    case "DELIVERED":
      return "darkgrey";
    case "IN_DISTRIBUTION":
      return "cadetblue";
    case "PAID":
      return "green";
    default:
      return "gray";
  }
};

const getEstadoTexto = (status) => {
  switch (status) {
    case "CANCELLED":
      return "Cancelado";
    case "PENDING_PAYMENT":
      return "Pendiente de pago";
    case "IN_PREPARATION":
      return "En preparación";
    case "DELIVERED":
      return "Enviado";
    case "IN_DISTRIBUTION":
      return "Recepcionado";
    case "PAID":
      return "Pagado";
    default:
      return status;
  }
};

// const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   background-color: #f2f2f2;
// `;

// const Card = styled.div`
//   background-color: white;
//   padding: 20px;
//   border-radius: 8px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   max-width: 800px;
//   width: 100%;
// `;

const Header = styled.div`
  margin-bottom: 20px;
`;

// const Title = styled.h2`
//   text-align: left;
// `;

const OrderInfo = styled.div`
  margin-bottom: 20px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const InfoLabel = styled.span`
  font-weight: bold;
`;

const InfoValue = styled.span``;

const StatusBadge = styled.span`
  padding: 5px 10px;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 12px;
  font-size: 12px;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const OrderTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

// const TableHeader = styled.thead`
//   background-color: #f8f8f8;
// `;

const TableBody = styled.tbody``;

// const TableRow = styled.tr`
//   border-bottom: 1px solid #ddd;
// `;

// const TableHeaderCell = styled.th`
//   padding: 12px;
//   text-align: left;
//   font-weight: bold;
// `;

// const TableCell = styled.td`
//   padding: 12px;
//   text-align: left;
// `;

const ProductImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  font-weight: bold;
`;

const TotalLabel = styled.span`
  margin-right: 20px;
`;

const TotalValue = styled.span``;

export default OrderDetail;