import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { getCategory, updateCategory } from "./services/Categories.services";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  Row,
  Column50,
  Form,
  FormRow,
  Label,
  Input,
  TextArea,
  Button,
  Breadcrumb
} from "../../styles/styledComponents";
import { ErrorAlert } from "../../components/ErrorAlert";

export const CategoriesEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId, categoryId } = location.state || {};
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    Name: "",
    Description: ""
    });

  useEffect(() => {
    const fetchProduct = async () => {
      if (categoryId) {
        try {
          const categoryData = await getCategory(categoryId, storeId);
          console.log("Category Data:", categoryData);
          if (categoryData && categoryData.category) {
            setFormData({
              Name: categoryData.category.name,
              Description: categoryData.category.description,
              });
          }
        } catch (error) {
          setError(error);
        }
      }
    };
    fetchProduct();
  }, [categoryId, storeId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validación para asegurarse de que los valores de porcentaje y cantidad mínima estén en el rango adecuado
    if ((name.includes("Percentage") || name.includes("MiniumQuantity")) && (value < 0 || value > 90)) {
      return; // Evita actualizar el estado si el valor está fuera de rango
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
  
      const response = await updateCategory(formData, categoryId ,storeId);
  
      if (response.errorCode) {
        console.error("Error creating category:", response);
        return;
      }
  
      // Redirigir después de crear el producto
      navigate("/tienda-categorias", { state: { storeId: storeId } });
    } catch (error) {
      setError(error); // Captura cualquier error
      console.error("Error creating category:", error);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
      .filter((file) => file.type.startsWith("image/"))
      .slice(0, 3 - formData.Images.length); // Limitar a máximo 3 imágenes
    setFormData((prev) => ({
      ...prev,
      Images: [...prev.Images, ...files],
    }));
  };

  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      Images: prev.Images.filter((_, i) => i !== index),
    }));
  };
  

  return (
    <Container>
      {error && <ErrorAlert error={error} />}

      <TitleContainer>
        <Title>Categorías</Title>
        <Breadcrumb
          items={[{ name: "Inicio", link: "/tienda" }, { name: "Tiendas", link: "/tienda" }, { name: "Actualizar categoría", link: "/" }]}
        />
      </TitleContainer>

      <Card>
        <Title2>Actualizar Categoría</Title2>

        <Form onSubmit={handleSubmit} style={{ marginRight: "-20px" }}>
          <h3>Información de la Categoría</h3>
          <Row>
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  name="Name" // Nombre del campo actualizado
                  value={formData.Name}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Descripción</Label>
                <TextArea
                  style={{ height: "82px" }}
                  name="Description" // Nombre del campo actualizado
                  value={formData.Description}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
            </Column50>
          </Row>
          {/* Botón de Enviar */}
          <Button style={buttonStyle} type="submit">Guardar Categoría</Button>
        </Form>
      </Card>
    </Container>
  );
};

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#d34343', // Color azul
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '20px',
  marginRight: '20px'
};

const ContainerImage = {
  border: '2px dashed #007bff',
  borderRadius: '10px',
  padding: '20px',
  textAlign: 'center',
  backgroundColor: '#f9f9f9',
  cursor: 'pointer',
  alignItems: 'center',
  marginBottom: '20px',
};

const PreviewContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
`;

const PreviewImage = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 0, 0, 0.7);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 12px;
`;

const InfoText = styled.p`
  font-size: 14px;
  color: #555;
`;
