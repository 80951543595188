import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { getProduct, updateProduct } from "./services/products.services";
import { getAllCategory } from "../categorias/services/Categories.services";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  Row,
  Column50,
  Form,
  FormRow,
  Label,
  Input,
  TextArea,
  Button,
  Breadcrumb
} from "../../styles/styledComponents";
import { ErrorAlert } from "../../components/ErrorAlert";

export const ProductsEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId, productId } = location.state || {};
  const [error, setError] = useState(null);
  const [categorias, setCategorias] = useState([]);


  const [formData, setFormData] = useState({
    Name: "", // Nombre del producto
    Quantity: 1, // Cantidad
    Description: "", // Descripción
    GrossPrice: 0, // Precio bruto
    NormalPrice: 0, // Precio normal
    MerchantPercentage: 0, // Porcentaje para comercio
    MerchantMiniumQuantity: 0, // Cantidad mínima para comercio
    WholesalerPercentage: 0, // Porcentaje para mayoristas
    WholesalerMiniumQuantity: 0, // Cantidad mínima para mayoristas
    DistributorsPercentage: 0, // Porcentaje para distribuidores
    DistributorsMiniumQuantity: 0, // Cantidad mínima para distribuidores
    OfferEnable: false, // Habilitar oferta
    OfferPercentage: 0, // Porcentaje de oferta
    CategoryName: "", // Categoría principal
    Images: [], // Imágenes
    ImagesURL: ["","",""]
  });

  useEffect(() => {
    const fetchProduct = async () => {
      if (productId) {
        try {
          const productData = await getProduct(productId, storeId);
          console.log("Product Data:", productData);
          if (productData && productData.product) {
            setFormData({
              Name: productData.product.name,
              Quantity: productData.product.quantity,
              Description: productData.product.description,
              NormalPrice: productData.product.prices.normalPrice, // Precio normal
              MerchantPercentage: productData.product.prices.merchant.percentage, // Porcentaje para comercio
              MerchantMiniumQuantity: productData.product.prices.merchant.miniumQuantity, // Cantidad mínima para comercio
              WholesalerPercentage: productData.product.prices.wholesaler.percentage, // Porcentaje para mayoristas
              WholesalerMiniumQuantity: productData.product.prices.wholesaler.miniumQuantity, // Cantidad mínima para mayoristas
              DistributorsPercentage: productData.product.prices.distributors.percentage, // Porcentaje para distribuidores
              DistributorsMiniumQuantity: productData.product.prices.distributors.miniumQuantity, // Cantidad mínima para distribuidores
              OfferEnable: productData.product.offer.enable, // Habilitar oferta
              OfferPercentage: productData.product.offer.percentage, // Porcentaje de oferta
              CategoryName: productData.product.category.name,
              Images: [],
              ImagesURL: ["","",""]
            });
          }
        } catch (error) {
          setError(error);
        }
      }
    };
    fetchProduct();
  }, [productId, storeId]);

  useEffect(() => {
    if (storeId) {
      getCategories(storeId);
    }
  }, [storeId]);

  const getCategories = async (storeId) => {
    try {
      const response = await getAllCategory(storeId);
      if (!response?.categories) {
        setError("Error al obtener las categorias");
      } else {
        setCategorias(response.categories);
        if (response.categories.length === 0) {
          setError(null); // Resetear el error si no hay productos pero no hay problema con la respuesta
        }
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setError("Error al obtener los categorias");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validación para campos de porcentaje y cantidad mínima
    if ((name.includes("Percentage") || name.includes("MiniumQuantity")) && (value < 0 || value > 90)) {
        return; // Evita actualizar el estado si el valor está fuera de rango
    }

    // Si el input es de tipo imagen (por URL)
    if (name.startsWith("imageURL_")) {
        const index = parseInt(name.split("_")[1], 10); // Extraer el índice del input
        setFormData((prev) => {
            const updatedImages = [...prev.ImagesURL];
            updatedImages[index] = value;
            return {
                ...prev,
                ImagesURL: updatedImages,
            };
        });
    } else {
        // Para el resto de los campos
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      // Convertir OfferEnable de "on"/"off" a booleano
      const offerEnableValue = formData.OfferEnable === "on"; // true si "on", false si "off"

      // Crear un objeto FormData
      const formDataToSend = new FormData();

      // Agregar los datos del formulario al FormData
      for (const key in formData) {
        if (key === "Images") {
          formData.Images.forEach((image) => {
            formDataToSend.append("Images", image);
          });
        } else if (key === "OfferEnable") {
          formDataToSend.append(key, offerEnableValue); // Enviar el valor booleano
        } else {
          formDataToSend.append(key, formData[key]); // No se necesita JSON.stringify
        }
      }

      // Mostrar el contenido de formDataToSend en la consola
      for (const [key, value] of formDataToSend.entries()) {
        console.log(`${key}:`, value);
      }

      // Llamar a la API para crear el producto
      const response = await updateProduct(formDataToSend, productId, storeId);

      if (response.errorCode) {
        console.error("Error creating product:", response);
        return;
      }

      // Redirigir después de crear el producto
      navigate("/tienda-productos", { state: { storeId: storeId } });
    } catch (error) {
      setError(error); // Captura cualquier error
      console.error("Error creating product:", error);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files)
      .filter((file) => file.type.startsWith("image/"))
      .slice(0, 3 - formData.Images.length); // Limitar a máximo 3 imágenes
    setFormData((prev) => ({
      ...prev,
      Images: [...prev.Images, ...files],
    }));
  };

  const handleFileChange = (event) => { 
    const files = Array.from(event.target.files)
      .filter((file) => file.type.startsWith("image/"))
      .slice(0, 3 - formData.Images.length); // Limitar a máximo 3 imágenes
  
    // Validación para verificar si algún archivo supera los 5MB
    const maxFileSize = 1 * 1024 * 1024; // 5 MB en bytes
    const invalidFiles = files.filter(file => file.size > maxFileSize);
  
    if (invalidFiles.length > 0) {
      alert("Una o más imágenes superan el tamaño máximo de 1 MB.");
      return; // Detener el proceso si alguna imagen es demasiado grande
    }
  
    // Si todas las imágenes son válidas, se actualiza el estado
    setFormData((prev) => ({
      ...prev,
      Images: [...prev.Images, ...files],
    }));
  };

  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      Images: prev.Images.filter((_, i) => i !== index),
    }));
  };


  return (
    <Container>
      {error && <ErrorAlert error={error} />}

      <TitleContainer>
        <Title>Productos</Title>
        <Breadcrumb
          items={[{ name: "Inicio", link: "/tienda" }, { name: "Tiendas", link: "/tienda" }, { name: "Actualizar producto", link: "/" }]}
        />
      </TitleContainer>

      <Card>
        <Title2>Actualizar Producto</Title2>

        <Form onSubmit={handleSubmit} style={{ marginRight: "-20px" }}>
          {/* Información del Producto */}
          <h3>Información del Producto</h3>
          <Row>
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Categoría</Label>
                <select style={{
                  backgroundColor: "#f2f2f2",
                  border: "none",
                  borderRadius: "5px",
                  padding: "10px"
                }}
                  name="CategoryName"
                  value={formData.CategoryName}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Seleccione una categoría</option>
                  <option value="Sin categoría">Sin categoría</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.name}>
                      {categoria.name}
                    </option>
                  ))}
                </select>
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Nombre del Producto</Label>
                <Input
                  type="text"
                  name="Name" // Nombre del campo actualizado
                  value={formData.Name}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Cantidad</Label>
                <Input
                  type="number"
                  name="Quantity" // Nombre del campo actualizado
                  value={formData.Quantity}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
            </Column50>

            {/* Descripción */}
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Descripción</Label>
                <TextArea
                  style={{ height: "82px" }}
                  name="Description" // Nombre del campo actualizado
                  value={formData.Description}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
            </Column50>
          </Row>

          {/* Precios y Cantidades Mínimas */}
          <h3>Precios y Cantidades Mínimas</h3>
          <Row>
            {/* Precio Normal y Porcentaje Comercio */}
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Precio Normal</Label>
                <Input
                  type="number"
                  name="NormalPrice" // Nombre del campo actualizado
                  value={formData.NormalPrice}
                  onChange={handleInputChange}
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Porcentaje Precio Comercio</Label>
                <Input
                  type="number"
                  name="MerchantPercentage" // Nombre del campo actualizado
                  value={formData.MerchantPercentage}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Cantidad Mínima Precio Comercio</Label>
                <Input
                  type="number"
                  name="MerchantMiniumQuantity" // Nombre del campo actualizado
                  value={formData.MerchantMiniumQuantity}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
            </Column50>

            {/* Por Mayor y Distribuidor */}
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Porcentaje Precio por Mayor</Label>
                <Input
                  type="number"
                  name="WholesalerPercentage" // Nombre del campo actualizado
                  value={formData.WholesalerPercentage}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Cantidad Mínima al por Mayor</Label>
                <Input
                  type="number"
                  name="WholesalerMiniumQuantity" // Nombre del campo actualizado
                  value={formData.WholesalerMiniumQuantity}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Porcentaje Precio Distribuidor</Label>
                <Input
                  type="number"
                  name="DistributorsPercentage" // Nombre del campo actualizado
                  value={formData.DistributorsPercentage}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Cantidad Mínima Precio Distribuidor</Label>
                <Input
                  type="number"
                  name="DistributorsMiniumQuantity" // Nombre del campo actualizado
                  value={formData.DistributorsMiniumQuantity}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
            </Column50>
          </Row>

          {/* Oferta */}
          <h3>Oferta</h3>
          <Row>
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>¿Habilitar Oferta?</Label>
                <Input
                  type="checkbox"
                  name="OfferEnable" // Nombre del campo actualizado
                  checked={formData.OfferEnable}
                  onChange={handleInputChange}
                />
              </FormRow>
              {formData.OfferEnable && (
                <FormRow style={{ marginRight: "20px" }}>
                  <Label>Porcentaje de Oferta</Label>
                  <Input
                    type="number"
                    name="OfferPercentage" // Nombre del campo actualizado
                    value={formData.OfferPercentage}
                    onChange={handleInputChange}
                    min="0"
                    max="90"
                  />
                </FormRow>
              )}
            </Column50>
          </Row>

          {/* Imágenes */}
          <h3>Imágenes</h3>
          <p> Cambia las imagenes de tu producto</p>
          <div style={{ color: 'black', backgroundColor: '#ADD8E6', padding: '20px', borderRadius: '10px', fontSize: '14px', width:"50%" }}>
            <p>ℹ️ Si necesitas agregar más de 3 imágenes a tu producto, puedes hacerlo combinando las dos opciones disponibles.</p>
            <p>ℹ️ Tamaño máximo de imagen: 1 MB. Si necesitas subir una imagen más grande, utiliza la opción 2 para cargarla por URL.</p>
          </div>
          <p>Opcion 1: Las imágenes del producto pueden ser cargadas desde tu dispositivo. Solo se permite un máximo de 3 imágenes.</p>
          <br />
          <FormRow>
            <Container
              style={ContainerImage}
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
              onClick={() => document.getElementById("fileInput").click()}
            >
              <p>Arrastra y suelta imágenes aquí, o haz clic para seleccionar.</p>
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </Container>
            <PreviewContainer>
              {formData.Images.map((image, index) => (
                <PreviewImage key={index}>
                  <Image src={URL.createObjectURL(image)} alt={`Preview ${index + 1}`} />
                  <RemoveButton onClick={() => handleRemoveImage(index)}>×</RemoveButton>
                </PreviewImage>
              ))}
            </PreviewContainer>
          </FormRow>
          <p>Opcion 2: Las imágenes del producto pueden ser referenciadas con un enlace externo.</p>
          <br />
          {formData.ImagesURL.map((url, index) => (
            <FormRow>
              <InfoGroup>
                <Label
                  style={{marginLeft:"10px"}}
                    >URL Imagen {index+1}:</Label>
                  <input
                    key={index}
                    name={`imageURL_${index}`}
                    onChange={handleInputChange}
                    type="text"
                    value={url}
                    placeholder="https://via.placeholder.com"
                    style={{borderRadius: "5px", border: "none", backgroundColor: "#f2f2f2", padding: "10px", marginLeft:"10px"}}
                  />
                  </InfoGroup>
                  </FormRow>
                ))}
          <br /><br />
          {/* Botón de Enviar */}
          <Button style={{ margin: "10px", background: '#d34343' }} type="submit">Guardar Producto</Button>
        </Form>
      </Card>
    </Container>
  );
};

export default ProductsEdit;

const ContainerImage = {
  border: '2px dashed #007bff',
  borderRadius: '10px',
  padding: '20px',
  textAlign: 'center',
  backgroundColor: '#f9f9f9',
  cursor: 'pointer',
  alignItems: 'center',
  marginBottom: '20px',
};

const InfoGroup = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`;

const PreviewContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
`;

const PreviewImage = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 0, 0, 0.7);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 12px;
`;

const InfoText = styled.p`
  font-size: 14px;
  color: #555;
`;
