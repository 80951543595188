import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllProducts, deleteProduct } from "./services/products.services";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  TableWrapper,
  Table,
  Button,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableCell,
  Breadcrumb
} from "../../styles/styledComponents";
import { ErrorAlert } from "../../components/ErrorAlert";

export const ProductsRead = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId } = location.state || {}; // Extraemos storeId del estado de navegación

  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState({
    startDateTime: "2024-10-10T15:36:37.831Z",
    endDateTime: "2024-10-10T15:36:37.831Z",
  });

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Activamos el loading al inicio de la petición
      try {
        const productData = await getAllProducts(filterData, storeId);
        if (!productData?.products) {
          setError("Error al obtener los productos");
        } else {
          setProductos(productData.products);
          if (productData.products.length === 0) {
            setError(null); // Resetear el error si no hay productos pero no hay problema con la respuesta
          }
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Error al obtener los productos");
      } finally {
        setLoading(false); // Aseguramos que el loading se apague siempre
      }
    };

    fetchProducts();
  }, [filterData, storeId]);

  const handleDelete = async (productId) => {
    try {
      await deleteProduct(productId, storeId);
      setProductos((prevProductos) =>
        prevProductos.filter((producto) => producto.id !== productId)
      );
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
      setError(error); // Almacena el error en el estado para que el componente ErrorAlert lo procese
    }
  };

  if (loading) return <div>Cargando...</div>;

  return (
    <Container>
        {error && <ErrorAlert error={error} />}
      <TitleContainer>
        <Title>TIENDAS</Title>
        <Breadcrumb items={[
            { name: "Inicio", link: "/tienda" },
            { name: "Tiendas", link: "/tienda" },
            { name: "Productos", link: "/tienda-productos" }
          ]} />
      </TitleContainer>    
      <Button
            style={buttonStyle2}
            onClick={() =>
              navigate("/tienda-categorias", { state: { storeId } })
            }
          >Categoría de Productos
          </Button>  
      <Card>
        <Header>
          <Title2>PRODUCTOS</Title2>
          <Button
            style={buttonStyle}
            onClick={() =>
              navigate("/tienda-productos-crear", { state: { storeId } })
            }
          >Crear Producto
          </Button>
        </Header>
        <TableWrapper>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>#</TableHeaderCell>
                <TableHeaderCell>Nombre</TableHeaderCell>
                <TableHeaderCell>Precio</TableHeaderCell>
                <TableHeaderCell>Precio Oferta</TableHeaderCell>
                <TableHeaderCell>Categiría</TableHeaderCell>
                <TableHeaderCell>Acción</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {productos.length === 0 ? (
                <TableRow>
                  <TableCell colSpan="6" style={{ textAlign: "center" }}>
                    {error ? error : "No hay productos disponibles."}
                  </TableCell>
                </TableRow>
              ) : (
                productos.map((producto, index) => (
                  <TableRow key={producto.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{producto.name}</TableCell>
                    <TableCell>
                      ${producto.prices.normalPrice.toLocaleString("es-ES")}
                    </TableCell>
                    <TableCell>
                      {producto.offer?.enable
                        ? `$${(producto.prices.normalPrice - (producto.prices.normalPrice * producto.offer.percentage/100)).toLocaleString("es-ES")} (Oferta: ${producto.offer.percentage}%)`
                        : "-"}
                    </TableCell>
                    <TableCell >{producto.category.name}</TableCell>
                    <TableCell>
                      <ActionButtonGroup>
                        <ActionButton
                          onClick={() =>
                            navigate("/tienda-productos-ver", {
                              state: { storeId, productId: producto.id },
                            })
                          }
                        >
                          👁️
                        </ActionButton>
                        <ActionButton
                          onClick={() =>
                            navigate("/tienda-productos-editar", {
                              state: { storeId, productId: producto.id },
                            })
                          }
                        >
                          ✏️
                        </ActionButton>
                        <ActionButton
                          onClick={() => handleDelete(producto.id)}
                        >
                          🗑️
                        </ActionButton>
                      </ActionButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Card>
    </Container>
  );
};

// Styled components

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableBody = styled.tbody``;

const ProductImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
`;

const ActionButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const ActionButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

const NoProductsMessage = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #888;
`;

export default ProductsRead;

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#d34343', // Color azul
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '20px',
};

const buttonStyle2 = {
  padding: '10px 20px',
  backgroundColor: '#6f6f6f', // Color azul
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  marginBottom: '20px',
};
